// EggManagerModal.tsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, TextField, Button } from '@mui/material';
import './console.css';
import { PiArrowsDownUpThin } from "react-icons/pi";
import { LuDot } from "react-icons/lu";
import { FaChevronRight } from "react-icons/fa6";



type Task = {
  _id: string;
  id_egg: string;
  creation: Date;
  cmd: string;
  args?: string[];
  response?: string;
  done: boolean;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  egg: Egg;
};

type Egg = {
  _id: string;
  last_activities: Date;
  egg_id: string;
  current_user: string;
  asset: string;
  listener: string;
  path: string;
  pwd?: string;
  name?: string;
  sleep: number;
  assetData?: Asset; // Supposé être une chaîne maintenant
  listenerNames?: string; // Supposé être une chaîne maintenant
  running?: boolean; // Nouveau champ pour le statut running
};

type Asset = {
  _id: string;
  mac: string;
  hostname: string;
  arch: string;
  os: string;
  ip?: string;
  pwn: boolean;
  network?: string;
  user?: string; // Supposé être une chaîne maintenant
}

const EggManagerModal: React.FC<Props> = ({ isOpen, onClose, egg }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const taskRef = useRef<Task[]>();
  const scrollRef = useRef(null);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const [modalHeight, setModalHeight] = useState(666); // Hauteur initiale de la modal
  const modalRef = useRef<HTMLDivElement>(null); // Référence pour le conteneur de la moda
  const [fontSize, setFontSize] = useState(14); // Taille de police initiale pour l'output
  const [cmd, setCmd] = useState('');
  const eggId = egg ? egg.egg_id : '';
  const eggName= egg ? egg.name : '';
  const assetHostname = egg ? egg.assetData?.hostname : '';

  const startResizing = (e: React.MouseEvent) => {
    // Empêche le comportement par défaut lors du démarrage du redimensionnement
    e.preventDefault();
    
    const startY = e.clientY; // Position initiale de la souris
    const startHeight = modalHeight; // Hauteur initiale de la modal

    const doResize = (moveEvent: MouseEvent) => {
      // Calcule la nouvelle hauteur basée sur le mouvement de la souris
      const newHeight = startHeight + (startY - moveEvent.clientY);
      setModalHeight(Math.max(newHeight, 140)); // Met à jour la hauteur, avec un minimum pour éviter une modal trop petite
    };

    const stopResize = () => {
      // Nettoie les gestionnaires d'événements lors de l'arrêt du redimensionnement
      window.removeEventListener('mousemove', doResize);
      window.removeEventListener('mouseup', stopResize);
    };

    // Ajoute les gestionnaires d'événements pour le redimensionnement
    window.addEventListener('mousemove', doResize);
    window.addEventListener('mouseup', stopResize);
  };



  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [tasks, isOpen]); // Dépendances : ré-exécute cet effet quand `tasks` ou `isOpen` change
  


  useEffect(() => {
    // Si le protocole est HTTP, utilisez ws:// sinon wss://
    let ws: WebSocket;
    let protocol: string;
    
    if (window.location.protocol === 'http:') {
       protocol = 'ws';
    } else {
       protocol = 'wss';
    }
    ws = new WebSocket(protocol+'://'+window.location.hostname+ ':' + window.location.port+'/api/');
    
  
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      switch (message.action) {
        case 'log':
          console.log(message.message);
          break;
        case 'addOrUpdateTask':
          if (!message.task) return;
          const newTask = message.task;
          // Utilisez setTasks avec une fonction de mise à jour pour parcourir l'état actuel et mettre à jour ou ajouter la nouvelle tâche
          setTasks(currentTasks => {
            const taskIndex = currentTasks.findIndex(task => task._id === newTask._id);
            if (taskIndex > -1) {
              // Si la tâche existe déjà, mettez à jour cette tâche spécifique
              const updatedTasks = [...currentTasks];
              updatedTasks[taskIndex] = newTask;
              return updatedTasks;
            } else {
              // Si la tâche n'existe pas, ajoutez-la au tableau
              return [...currentTasks, newTask];
            }
          });
          break;
        default:
          console.log('Action inconnue');
      }
    };
    return () => {
      ws.close();
    };
  }, []); // Cet effet ne dépend d'aucune variable extérieure
  
  

  useEffect(() => {
    taskRef.current = tasks;
  }, [tasks]);

  useEffect(() => {
    // Supposons que vous avez un endpoint pour récupérer les tasks d'un egg spécifique
    const fetchTasks = async () => {
      try {
        const response = await axios.get<Task[]>(`/api/task/list?eggid=${eggId}`);
        setTasks(response.data);
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
      }
    };

    if (isOpen) {
      fetchTasks();
    }
  }, [isOpen, eggId]);

  const handleSendCmd = async () => {
    if (cmd.trim() === '') return; // Vérifiez que la commande n'est pas vide
  
    try {
      // Supposons que cmd est une chaîne de texte et args un tableau d'arguments, ajustez selon votre cas
      const taskData = {
        cmd: cmd,
        args: [], // Ajustez cette partie selon la manière dont vous gérez les arguments de commande
      };
  
      await axios.put(`/api/task/add/${eggId}`, taskData, {
        // Assurez-vous d'inclure les headers nécessaires pour l'authentification si votre API le requiert
      });
  
      setCmd(''); // Réinitialiser le champ de commande après l'envoi
      // Optionnel: Actualiser la liste des tâches après l'ajout d'une nouvelle tâche
      // fetchTasks(); // Vous pouvez appeler fetchTasks ici pour rafraîchir la liste des tâches
    } catch (error) {
      console.error('Failed to send command:', error);
      // Gérer l'erreur, par exemple en affichant un message à l'utilisateur
    }
  };
  
  const handleFontResize = (e: React.KeyboardEvent) => {
    // Détecter 'Ctrl +' pour augmenter la taille de la police
    if (e.ctrlKey && e.key === '+' || e.ctrlKey && e.key === '=' || e.shiftKey && e.ctrlKey && e.key === '+') {
      setFontSize((currentSize) => Math.min(currentSize + 1, 28)); // Plafonnez la taille de police à 24px, ajustez selon vos besoins
      e.preventDefault(); // Empêcher l'action par défaut pour éviter tout comportement inattendu
    }
    // Détecter 'Ctrl -' pour diminuer la taille de la police
    else if (e.ctrlKey && e.key === '-') {
      setFontSize((currentSize) => Math.max(currentSize - 1, 8)); // Ne descendez pas en dessous de 8px, ajustez selon vos besoins
      e.preventDefault(); // Empêcher l'action par défaut
    }
  };

  const handleHistory = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault(); // Prevent cursor from moving to the start of the text field
      // Calculate new history index, considering the current position
      const newIndex = historyIndex + 1;
      if (newIndex < tasks.length) {
        const taskCmd = tasks[tasks.length - 1 - newIndex]?.cmd;
        const taskArgs = tasks[tasks.length - 1 - newIndex]?.args;

        if (taskCmd) {
          setCmd(taskCmd + ' ' + taskArgs.join(' '));
          setHistoryIndex(newIndex);
        }
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      // Navigate down in the command history, or reset to empty if index reaches -1
      const newIndex = historyIndex - 1;
      if (newIndex >= -1) {
        if (newIndex === -1) {
          setCmd('');
        } else {
          const taskCmd = tasks[tasks.length - 1 - newIndex]?.cmd;
          const taskArgs = tasks[tasks.length - 1 - newIndex]?.args;

          setCmd(taskCmd + ' ' + taskArgs.join(' '));
        }
        setHistoryIndex(newIndex);
      }
    }
  };

  const outputStyle = {
    fontSize: `${fontSize}px`,
    // Autres styles de .output si nécessaire
  };
  return (
    <Modal open={isOpen} className='modal' onClose={onClose}>
      <div className='divModal' ref={modalRef} style={{ height: `${modalHeight}px` }}>
      <div onMouseDown={startResizing} className='bar-resize'>
      <PiArrowsDownUpThin className='arrow' size={20}/>
        </div>
        <h2>Terminal - {eggName} - {assetHostname} - {eggId} </h2>
        <div className='allTerminal'>
          <div
            ref={scrollRef}
            className='output'
            style={outputStyle} // Appliquez le style ici

          >
            {tasks.map((task, index) => (
              <div key={index}>
                <div className='inputSend'>{task.done ? <LuDot size={35} color='green'/>:<LuDot size={35} color='orange'/>}{new Date(task.creation).toLocaleString()}<FaChevronRight size={10} />      {task.cmd} {task.args ? task.args.join(' ') : ''}</div>
                <div className='outputReceive'>{task.response}</div>
              </div>
            ))}
          </div>
          </div>

          <div className='input'>
            <TextField
              fullWidth
              variant="outlined"
              value={cmd}
              onChange={(e) => setCmd(e.target.value)}
              placeholder="Type help get command on this agent..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendCmd();
                  // Reset history index on new command submission
                  setHistoryIndex(-1);
                }
              }}
              
              onKeyDown={(e) => {
                handleFontResize(e); // Gérez le redimensionnement de la police ici
                handleHistory(e)
              }}
            />
          </div>
      </div>
    </Modal>
  );
};

export default EggManagerModal;
