import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Définition du type pour un listener
type ListenerProfile = {
  _id: string;
  name: string;
  type: string;
  domain: string;
  port: string;
  endpoint: string;
  data_parameter: string;
  data_method: string;
  data_field: string;
  data_encode: string;
  data_encrypt?: string;
  key?: string;
  encrypted: boolean;
  fake_data: string[];
  listener_linked?: string[];
  listenerNames?: string[]; // Nouvelle propriété pour les noms des profils

};

const ListenersTable: React.FC = () => {
  const [listeners, setListeners] = useState<ListenerProfile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProfile, setSelectedProfile] = useState<ListenerProfile | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
  const fetchProfilesAndListeners = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get<ListenerProfile[]>('/api/listener/list/profile');
        const profilesWithListenerNames = await Promise.all(response.data.map(async (profiles) => {
          if (profiles.listener_linked && profiles.listener_linked.length > 0) {
            const listenerNames = await fetchListenerNamesByIds(profiles.listener_linked);
            return { ...profiles, listenerNames }; // Ajoute un champ `profileNames`
          }
          return profiles; // Retourne le profile tel quel si pas de profile
        }));
        setListeners(profilesWithListenerNames);
        setError(null);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.response?.data.error || error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProfilesAndListeners();
  }, []);


  const handleProfileClick = (profileId: string) => {
    // Trouver le profil dans la liste des profils chargés
    const profile = listeners.find(listener => listener._id === profileId);
    setSelectedProfile(profile);
    setIsModalOpen(true);
  };
  


  const fetchListenerNamesByIds = async (listenerIds: string[]) => {
    if (listenerIds.length === 0) {
      return [];
    }
    
    try {
      const listenerNames = await Promise.all(listenerIds.map(async (id) => {
        const response = await axios.get(`/api/listener/list?_id=${id}`);
        return response.data[0].name; // Assurez-vous que l'API renvoie bien un champ `name`
      }));
      return listenerNames;
    } catch (error) {
      console.error('Failed to fetch profile names:', error);
    }
  };
  
  
  const handleAction = async (_id: string, action: 'start' | 'stop' | 'delete') => {
    try {
      let response;
     if (action === 'delete') {
        response = await axios.delete(`/api/listener/profile/delete/${_id}`);
      }
      // Refresh the list after the action
      const updatedList = await axios.get<ListenerProfile[]>('/api/listener/list/profile');
      setListeners(updatedList.data);
    } catch (error) {
      console.error('Failed to perform action:', error);
      setError(error.response?.data.error || error.message);
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (listeners.length === 0) return <p>No listener profile found.</p>;

  return (
    <div>
      <h2>List of Listeners</h2>
      <div className='error'><p>{error}</p></div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {listeners.map((listener) => (
            <tr key={listener._id}>
              <td onClick={() => handleProfileClick(listener._id)} style={{ cursor: 'pointer' }}>
        {listener.name}
      </td>
              <td>{listener.type}</td>
              <td>
                  {listener.listenerNames && listener.listenerNames.length > 0 ? listener.listenerNames.join(', ') : 'No linked profiles'}
              </td>
              <td>
                <button onClick={() => handleAction(listener._id, 'delete')} style={{color: 'red'}}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && selectedProfile && (
    <div className="modal">
      <h3>Profile Details</h3>
      <p>Name: {selectedProfile.name}</p>
      <p>Type: {selectedProfile.type}</p>
      <p>Domain: {selectedProfile.domain}</p>
      <p>Port: {selectedProfile.port}</p>
      <p>Endpoint: {selectedProfile.endpoint}</p>
      <p>Data parameter: {selectedProfile.data_parameter}</p>
      <p>Data method: {selectedProfile.data_method}</p>
      <p>Data field: {selectedProfile.data_field}</p>
      <p>Encoding: {selectedProfile.data_encode}</p>
      <p>Encrypted: {selectedProfile.encrypted ? 'true' : 'false'}</p>
      <p>Fake data: {selectedProfile.fake_data.length > 0 ? selectedProfile.fake_data : "No fake data added" }</p>

      






      {/* Ajoutez d'autres détails du profil que vous souhaitez afficher */}
      <button onClick={() => setIsModalOpen(false)}>Close</button>
    </div>
  )}
    </div>
  );
};

export default ListenersTable;
