// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allColumn {
    display: flex;
    width: 100%;
}



.rightColumn{
    /* background-color: red; */
    margin-left: 100px;
    width: 60%;
    display: flex;
}

.rightColumn div {
    display: flex;
    align-items: center;
}
.rightColumn div select{
    /* background-color: yellow; */
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/listener/listener.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;;;;AAIA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,8BAA8B;IAC9B,WAAW;AACf","sourcesContent":[".allColumn {\n    display: flex;\n    width: 100%;\n}\n\n\n\n.rightColumn{\n    /* background-color: red; */\n    margin-left: 100px;\n    width: 60%;\n    display: flex;\n}\n\n.rightColumn div {\n    display: flex;\n    align-items: center;\n}\n.rightColumn div select{\n    /* background-color: yellow; */\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
