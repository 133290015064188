import React from 'react';
import Header from '../../components/core/Header'; // Vérifiez le chemin d'importation
import SideMenu from '../../components/core/SideMenu'; // Vérifiez le chemin d'importation
import DropFile from '../../components/filemanager/DropFile'; // Vérifiez le chemin d'importation
import ListFiles from '../../components/filemanager/ListFiles'; // Vérifiez le chemin d'importation




import '../../App.css';


const FileManagerPage = () => {
    
    return (
        <div className='body'>
            <SideMenu/>
            <div className='core'>
                <Header title='File manager'/>
                <div className='content'>
                    <div className='component'> <DropFile /></div>
                    <div className='component'> <ListFiles /></div>
                </div>
            </div>
        </div>
    );

};

export default FileManagerPage;
