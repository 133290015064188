import React from 'react';

// Définition des props attendues par le composant Dashboard
type DashboardProps = {
    title: string; // Vous pouvez ajouter d'autres props ici selon les besoins
};

const Dashboard: React.FC<DashboardProps> = ({ title }) => {
    return (
        <div>
            {/* Affichage du titre passé en prop */}
            <h1>{title}</h1>
        </div>
    );
};

export default Dashboard;
