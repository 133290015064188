// fichier : src/helpers/buildTree.js

/**
 * Transforme une liste plate en structure arborescente basée sur les chemins des fichiers.
 * @param {Array} items - La liste plate des éléments avec les chemins et isDirectory.
 * @returns {Array} La structure arborescente des éléments.
 */
let nodeId = 0; // Compteur pour générer des ID uniques

export const buildTree = (items) => {
    const root = { id: `${nodeId++}`, name: 'Root', children: [], isDirectory: true }; // Ajoutez un id unique à la racine
  
    items.forEach((item) => {
      const splitPath = item.path.split('/');
      let currentLevel = root.children; // Commencez à la racine
  
      splitPath.forEach((part, index) => {
        let existingPath = currentLevel.find(p => p.name === part);
  
        if (!existingPath) {
          if (index === splitPath.length - 1) {
            // C'est un fichier, ajoutez-le directement avec un id unique
            currentLevel.push({ id: `${nodeId++}`, ...item });
          } else {
            // C'est un dossier, créez un nouvel objet de dossier avec un id unique
            const newDir = { id: `${nodeId++}`, name: part, path: splitPath.slice(0, index + 1).join('/'), children: [], isDirectory: true };
            currentLevel.push(newDir);
            currentLevel = newDir.children; // Descendez d'un niveau pour la prochaine itération
          }
        } else {
          // Le dossier existe déjà, descendez dans l'arborescence existante
          currentLevel = existingPath.children;
        }
      });
    });
  
    return root.children; // Retournez les enfants de la racine pour obtenir l'arborescence complète
  };
  