import React, { useCallback, useState } from 'react';
import { Button, TextField, Snackbar } from '@mui/material';
import { BsFileEarmark, BsFileEarmarkCheck, BsUpload } from "react-icons/bs";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios'; // Importez Axios
import './filemanager.css';

const DropFile: React.FC = () => {
  const [dragOver, setDragOver] = useState(false);
  const [fileName, setFileName] = useState('Drag and drop a file here or click to select');
  const [folder, setFolder] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
  }, []);

  const processFiles = useCallback((files: FileList) => {
    if (files.length) {
      const selectedFile = files[0];
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setUploadSuccess(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
    processFiles(files);
  }, [processFiles]);

  const handleChange = useCallback((e) => {
    const files = e.target.files;
    if (files) {
      processFiles(files);
    }
  }, [processFiles]);

  const handleFolderChange = useCallback((e) => {
    setFolder(e.target.value);
  }, []);

  const createResponseMessage = (icon, message) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <span style={{ marginLeft: '10px' }}>{message}</span>
    </div>
  );
  const handleCloseSnackbar = () => {
    setShowResponseMessage(false);
  };

  const handleUploadClick = useCallback(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folder', folder);
  
      setUploading(true);
      axios.post('/api/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        setUploadSuccess(true);
        setResponseMessage(createResponseMessage(<FaCheckCircle color="green" />, response.data.message));
        setShowResponseMessage(true);
      })
      .catch(error => {
        setUploadSuccess(false);
        setResponseMessage(createResponseMessage(<FaTimesCircle color="red" />, 'Impossible to upload file: ' + (error.response.data.error || 'Unknown error')));
        setShowResponseMessage(true);
      })
      .finally(() => {
        setUploading(false);
      });
    }
  }, [file, folder]);

  return (
    <div className='DropFile'
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
        width: '100%',
        height: '400px',
        color: dragOver ? '#000' : '#aaa',
        textAlign: 'center',
        lineHeight: '100px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column', // Modifier pour 'column' pour aligner verticalement
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className='DropFileInput'>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
          id="fileInput"
        />
        {uploading ? 'Uploading...' : (uploadSuccess ? <BsFileEarmarkCheck size={70}/> : <BsFileEarmark size={70} />)}
        
        <label htmlFor="fileInput" style={{ cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}>
          {fileName}
        </label>
        <TextField
          label="Folder (optional)"
          variant="outlined"
          size="small"
          value={folder}
          onChange={handleFolderChange}
          style={{ marginTop: '10px' }}
        />
        {file && (
          <Button onClick={handleUploadClick} disabled={uploading} className="uploadButton" style={{ marginTop: '10px' }}>
            {uploading ? 'Uploading...' : <BsUpload />}
            UPLOAD
          </Button>
        )}
      </div>
      <Snackbar
        open={showResponseMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={responseMessage}
      />
    </div>
    
  );
};

export default DropFile;
