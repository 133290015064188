// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terminalBuilder {
    background-color: black;
    color: white;
    max-height: 400px; /* Ajustez selon le besoin */
    overflow-y: auto; /* Permet le défilement vertical si nécessaire */
    font-family: monospace;
    padding: 15px;
    
  }

  .boxModalPayload {
    display: flex;
    flex-direction: column; /* Organise les enfants de la boîte verticalement */
    max-height: 80vh; /* Utilisez vh pour une hauteur relative à la fenêtre du navigateur */
    background: white;
    border-radius: 4px;
    padding: 20px;
    width: 60%;
    overflow-y: auto; /* Permet le défilement si le contenu de la modal dépasse */
  }

  .divButton {
    text-align: center;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

.modalJson { 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalJson button { 
    color: white;
background-color : darkgray
  }

.insideModalJson { 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.insideModalJson textarea { 
  max-height: 90vh;
  overflow: scroll !important;
}`, "",{"version":3,"sources":["webpack://./src/components/listener/modals/generatePayload.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB,EAAE,4BAA4B;IAC/C,gBAAgB,EAAE,gDAAgD;IAClE,sBAAsB;IACtB,aAAa;;EAEf;;EAEA;IACE,aAAa;IACb,sBAAsB,EAAE,mDAAmD;IAC3E,gBAAgB,EAAE,qEAAqE;IACvF,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,gBAAgB,EAAE,2DAA2D;EAC/E;;EAEA;IACE,kBAAkB;IAClB,oBAAoB;IACpB,aAAa;IACb,sBAAsB;EACxB;;AAEF;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,YAAY;AAChB;EACE;;AAEF;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;AAEvB;;;AAGA;EACE,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":[".terminalBuilder {\n    background-color: black;\n    color: white;\n    max-height: 400px; /* Ajustez selon le besoin */\n    overflow-y: auto; /* Permet le défilement vertical si nécessaire */\n    font-family: monospace;\n    padding: 15px;\n    \n  }\n\n  .boxModalPayload {\n    display: flex;\n    flex-direction: column; /* Organise les enfants de la boîte verticalement */\n    max-height: 80vh; /* Utilisez vh pour une hauteur relative à la fenêtre du navigateur */\n    background: white;\n    border-radius: 4px;\n    padding: 20px;\n    width: 60%;\n    overflow-y: auto; /* Permet le défilement si le contenu de la modal dépasse */\n  }\n\n  .divButton {\n    text-align: center;\n    padding-bottom: 15px;\n    display: flex;\n    flex-direction: column;\n  }\n\n.modalJson { \n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .modalJson button { \n    color: white;\nbackground-color : darkgray\n  }\n\n.insideModalJson { \n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n}\n\n\n.insideModalJson textarea { \n  max-height: 90vh;\n  overflow: scroll !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
