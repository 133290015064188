// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header h1{
    margin: 0;
    padding-top: 1%;
    align-self: center;
    
}

`, "",{"version":3,"sources":["webpack://./src/components/core/css/Header.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,eAAe;IACf,kBAAkB;;AAEtB","sourcesContent":["header h1{\n    margin: 0;\n    padding-top: 1%;\n    align-self: center;\n    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
