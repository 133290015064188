// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropFileInput {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.DropFile {
    display: flex;
    justify-content: center;
}

.DropFile button {
 
    padding: 6px 16px;
    border-radius: 5px;
    /* background-color: #1976d2;
    color: white; */
    font-size: 15px;
    border: 0;
    letter-spacing: 1px;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.75;
    align-items: center;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}


.DropFileInput label {
    margin-right: 0;

}`, "",{"version":3,"sources":["webpack://./src/components/filemanager/filemanager.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;;IAEI,iBAAiB;IACjB,kBAAkB;IAClB;mBACe;IACf,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gHAAgH;AACpH;;;AAGA;IACI,eAAe;;AAEnB","sourcesContent":[".DropFileInput {\n    display: flex;\n    flex-direction: column;\n    /* justify-content: center; */\n    align-items: center;\n}\n\n.DropFile {\n    display: flex;\n    justify-content: center;\n}\n\n.DropFile button {\n \n    padding: 6px 16px;\n    border-radius: 5px;\n    /* background-color: #1976d2;\n    color: white; */\n    font-size: 15px;\n    border: 0;\n    letter-spacing: 1px;\n    cursor: pointer;\n    font-weight: 500;\n    line-height: 1.75;\n    align-items: center;\n    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);\n}\n\n\n.DropFileInput label {\n    margin-right: 0;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
