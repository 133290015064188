// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './pages/auth/AuthContext'; 
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/whitecell/Dashboard_page';
import Listener from './pages/redcell/Listener_page';
import ListenerProfile from './pages/redcell/ListenerProfile_page';
import EggManager from './pages/redcell/EggManager_page';
import FileManager from './pages/redcell/FileManager_page';




const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  return auth.isAuthenticated ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, role } = useAuth(); // Utilisez la déstructuration pour récupérer isAuthenticated et role

  // Si l'utilisateur est authentifié et son rôle est "redcell", redirigez-le vers "/egg_manager"
  if (isAuthenticated && role === "redcell") {
    return <Navigate to="/red/egg_manager" />;
  }
  if (isAuthenticated) {
    return <Navigate to="/red/egg_manager" />;
  }

  return  children 
  // Si l'utilisateur est authentifié mais que son rôle n'est pas "redcell", vous pouvez le rediriger vers une autre page, par exemple "/white/dashboard"

};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
          <Route path="/white/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/red/listener" element={<PrivateRoute><Listener /></PrivateRoute>} />
          <Route path="/red/listener/profile" element={<PrivateRoute><ListenerProfile /></PrivateRoute>} />
          <Route path="/red/egg_manager" element={<PrivateRoute><EggManager /></PrivateRoute>} />
          <Route path="/red/file_manager" element={<PrivateRoute><FileManager /></PrivateRoute>} />
          <Route path="/" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
