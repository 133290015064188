import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GeneratePayloadModal from './modals/GeneratePayload_modal';


// Définition du type pour un listener
type Listener = {
  _id: string;
  name: string;
  type: string;
  port: number;
  profile: string[]; // ID des profils
  useSelf: boolean;
  running: boolean;
  profileNames?: string[]; // Nouvelle propriété pour les noms des profils
};


const ListenersTable: React.FC = () => {
  const [listeners, setListeners] = useState<Listener[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isGeneratorModalOpen, setIsGeneratorModalOpen] = useState(false);
  const [selectedListener, setSelectedListener] = useState<Listener | null>(null);

  const handleAction = async (_id: string, action: 'start' | 'stop' | 'delete') => {
    try {
      let response;
      if (action === 'start' || action === 'stop') {
        response = await axios.get(`/api/listener/${action}/${_id}`);
      } else if (action === 'delete') {
        response = await axios.delete(`/api/listener/delete/${_id}`);
      }
      // Refresh the list after the action
      const updatedList = await axios.get<Listener[]>('/api/listener/list');
      const listenersWithProfileNames = await Promise.all(updatedList.data.map(async (listener) => {
        if (listener.profile && listener.profile.length > 0) {
          const profileNames = await fetchProfileNamesByIds(listener.profile);
          return { ...listener, profileNames }; // Ajoute un champ `profileNames`
        }
        return listener; // Retourne le listener tel quel si pas de profile
      }));
      setListeners(listenersWithProfileNames);
    } catch (error) {
      console.error('Failed to perform action:', error);
      setError('Failed to perform action. Please try again.');
    }
  };

  const openGeneratorModal = (listener: Listener) => {
    setSelectedListener(listener);
    setIsGeneratorModalOpen(true);
  };

  useEffect(() => {
    const fetchListenersAndProfiles = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get<Listener[]>('/api/listener/list');
        const listenersWithProfileNames = await Promise.all(response.data.map(async (listener) => {
          if (listener.profile && listener.profile.length > 0) {
            const profileNames = await fetchProfileNamesByIds(listener.profile);
            return { ...listener, profileNames }; // Ajoute un champ `profileNames`
          }
          return listener; // Retourne le listener tel quel si pas de profile
        }));
        setListeners(listenersWithProfileNames);
        setError(null);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.response?.data.error || error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchListenersAndProfiles();
  }, []);
  
  

  const fetchProfileNamesByIds = async (profileIds: string[]) => {
    if (profileIds.length === 0) {
      return [];
    }
    
    try {
      const profileNames = await Promise.all(profileIds.map(async (id) => {
        const response = await axios.get(`/api/listener/list/profile?_id=${id}`);
        if (!response.data || response.data.length === 0) {
           return 'Profile deleted'; // Retourne une valeur par défaut si le profil n'est pas trouvé
        }
        return response.data[0].name; // Assurez-vous que l'API renvoie bien un champ `name`
      }));
      return profileNames;
    } catch (error) {
      console.error('Failed to fetch profile names:', error.data.message.message);
      throw new Error('Failed to fetch profile names.');
    }
  };
  
  

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (listeners.length === 0) return <p>No listener found.</p>;

  return (
    <div>
      <h2>List of Listeners</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Port</th>
            <th>Profile</th>
            <th>Self Profile</th>
            <th>Running</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {listeners.map((listener) => (
    <tr key={listener._id}>
      <td>{listener.name}</td>
      <td>{listener.type}</td>
      <td>{listener.port}</td>
      <td>{listener.profileNames && listener.profileNames.length > 0 ? listener.profileNames.join(', ') : 'No profile linked'}</td>
      <td>{listener.useSelf ? 'Yes' : 'No'}</td>
      <td>{listener.running ? 'Yes' : 'No'}</td>
      <td>
          <button onClick={() => handleAction(listener._id, listener.running ? 'stop' : 'start')}>
            {listener.running ? 'Stop' : 'Start'}
          </button>
          <button onClick={() => openGeneratorModal(listener)}>Generate</button>
    
          <button onClick={() => handleAction(listener._id, 'delete')} style={{color: 'red'}}>
            Delete
          </button>
        </td>
    </tr>
  ))}
</tbody>

      </table>
      <GeneratePayloadModal  isOpen={isGeneratorModalOpen} onClose={() => setIsGeneratorModalOpen(false)} listener={selectedListener} />

    </div>
    
  );
};

export default ListenersTable;
