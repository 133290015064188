// src/auth/Login.tsx
import React, { useState } from 'react';
import './css/Header.css';


type HeaderProps = {
    title: string; // Vous pouvez ajouter d'autres props ici selon les besoins
};

const Header: React.FC<HeaderProps> = ({ title }) => {


  return (
    <header>
        <h1>{title}</h1>
    </header>
  );
};

export default Header;
