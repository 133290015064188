import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './listener.css';
import Select from 'react-select';



type FormState = {
  name: string;
  type: string;
  host: string;
  profile?: string[]; // Ceci stockera l'ID du profil sélectionné
  useSelf: boolean; // Nouveau champ pour la case à cocher
};

type ProfileOption = {
  _id: string;
  name: string;
};

const CreateListenerForm: React.FC = () => {
  const [formData, setFormData] = useState<FormState>({ name: '', type: '', host : window.location.hostname, profile: [],  useSelf: false });
  const [profiles, setProfiles] = useState<ProfileOption[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [profileTypes, setProfileTypes] = useState<string[]>([]);
  const profileOptions = profiles.map(profile => ({ value: profile._id, label: profile.name }));

  const handleSelectChange = selectedOptions => {
    // 3. Modifier handleChange pour gérer les sélections de react-select
    const selectedProfiles = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData(prevData => ({
      ...prevData,
      profile: selectedProfiles,
    }));
  };


  useEffect(() => {
    const fetchProfiles = async () => {
      // Vérifiez si un type est sélectionné avant de charger les profils
      if (!formData.type) return;
  
      try {
        // Incluez le type sélectionné comme paramètre de requête
        const response = await axios.get<ProfileOption[]>(`/api/listener/list/profile?type=${formData.type}`);
        setProfiles(response.data);
      } catch (error) {
        console.error('Failed to fetch profiles based on type', error);
        setError('Failed to load profiles for the selected type.');
      }
    };
  
    // Appel à fetchProfiles à chaque changement de formData.type
    fetchProfiles();
  }, [formData.type]); // Dépendance à formData.type pour recharger les profils quand le type change
  

  useEffect(() => {
    const fetchProfileTypes = async () => {
      try {
        const response = await axios.get('/api/listener/list/types');
        const types = response.data.typesSupported; // Assurez-vous que c'est le bon chemin d'accès
        setProfileTypes(types);
        if (types.length > 0 && !formData.type) {
          setFormData(prevFormData => ({ ...prevFormData, type: types[0] }));
        }
      } catch (error) {
        console.error('Failed to fetch profile types:', error);
        setError('Failed to load profile types.');
      }
    };
  
    fetchProfileTypes();
  }, []);


 const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
  const { name, value, checked, type, selectedOptions } = event.target as HTMLInputElement & { selectedOptions: HTMLOptionsCollection };

  if (type === 'checkbox') {
    setFormData(prevData => ({
      ...prevData,
      [name]: checked,
    }));
  } else if (name === "profile" && selectedOptions) { // Gérer les sélections multiples pour les profils
    const selectedProfiles = Array.from(selectedOptions).map(option => option.value).filter(v => v); // Filtre les valeurs vides
    setFormData(prevData => ({
      ...prevData,
      profile: selectedProfiles.length > 0 ? selectedProfiles : [],
    }));
  } else {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  }
};
  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post('/api/listener/create', formData);
      setMessage('Listener successfully created.');
      setFormData({ name: '', type: 'http', host : window.location.hostname, useSelf: false }); // Réinitialiser le formulaire après la création
      window.location.reload();
    } catch (error) {
      setError(error.response?.data.error || error.message);    } finally {
      setIsSubmitting(false);
    }
  };

  // Déterminer si le bouton de soumission doit être désactivé
  const isSubmitDisabled = isSubmitting || (!formData.useSelf && !formData.profile);

  return (
    <div>
      <h2>Create Listener</h2>
      <p><Link to="/red/listener/profile/">Manage listener profile</Link>.</p>
      <form onSubmit={handleSubmit}>
        <div className='allColumn'>
          <div className='leftColumn'>
            <div>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="type">Type:</label>
              <select id="type" name="type" value={formData.type} onChange={handleChange} required>
                {profileTypes.map((type) => (
                  <option key={type} value={type}>{type.toUpperCase()}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="host">C2 Host:</label>
              <input type="text" id="host" name="host" value={formData.host} onChange={handleChange} required />
            </div>
            <div>
              <label>
                <input type="checkbox" name="useSelf" checked={formData.useSelf} onChange={handleChange} />
                Use self as listener
              </label>
            </div>
          </div>
          <div className='rightColumn'>
            <div>
              <label htmlFor="profile">Profile:</label>
             {profiles.length > 0 ? (
            // 4. Remplacer le composant <select> par Select de react-select
            <Select
              id="profile"
              name="profile"
              options={profileOptions}
              isMulti={true}
              onChange={handleSelectChange}
              value={profileOptions.filter(option => formData.profile?.includes(option.value))}
            />
          ) : (
            <p>No profiles available. <Link to="/red/listener/profile/">Create a profile</Link>.</p>
          )}
        </div>
          </div>
        </div>
        <button type="submit" disabled={isSubmitDisabled}>Create Listener</button>
      </form>
      {error && <div className='error'><p>{error}</p></div>}
      {message &&  <div className='success'><p>{message}</p></div>}

    </div>
  );
};

export default CreateListenerForm;
