import React, { useEffect, useState } from 'react';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { buildTree } from './helpers/buildTree';
import { FaFolderPlus } from "react-icons/fa";
import { CiFileOn } from "react-icons/ci";
import { Snackbar } from '@mui/material'; // Assurez-vous d'importer TextField également
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { GrFormClose } from "react-icons/gr";

import axios from 'axios'; // Importez Axios

const ListFiles: React.FC = () => {
  const [files, setFiles] = useState([]);
  const [fileExist, setFileExist] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false); // Contrôle l'affichage de la Snackbar
  const [responseMessage, setResponseMessage] = useState(''); // Stocke le message de réponse


  useEffect(() => {
    axios.get('/api/file/list')
      .then(response => {
        const tree = buildTree(response.data); // Utilisez response.data pour accéder au corps de la réponse
        setFiles(tree);
        if (tree.length > 0) {
          setFileExist(true)
        }
      })
      .catch(error => console.error('Error fetching files:', error));
  }, []);

  const renderTree = (node) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      onClick={(event) => {
        event.stopPropagation(); // Empêche le clic de se propager aux parents
        if (!node.isDirectory) {
          handleFileClick(node); // Gère le téléchargement pour les fichiers
        }
      }}
      label={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {node.name}
          <GrFormClose
            color="red"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={(event) => handleDeleteClick(event, node)}
          />
        </div>
      }
    >
      {Array.isArray(node.children) ? node.children.map((child) => renderTree(child)) : null}
    </TreeItem>
  );

  const handleFileClick = (node) => {
    console.log(node)
    console.log(node.isDirectory)

    if (node.isDirectory) {
      return
    }
    const downloadUrl = `/api/file/download`;
    
    axios({
      url: downloadUrl,
      method: 'POST',
      responseType: 'blob', // Important pour traiter le contenu binaire du fichier
      data: {
        path: encodeURIComponent(node.path)
      }
    })
    .then(response => {
      // Crée un nouvel objet URL pour le fichier blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Crée un lien temporaire et simule un clic dessus pour télécharger le fichier
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', node.name); // Définit le nom du fichier à télécharger
      document.body.appendChild(link);
      link.click();
      
      // Nettoie et supprime le lien une fois le téléchargement initié
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url); // Libère l'objet URL
    })
    .catch(error => {
  
      setResponseMessage(createResponseMessage(<FaTimesCircle color="red" />, 'Impossible to download file'));
      setShowResponseMessage(true);
      console.error('Download error:', error)
    })  
  }

  const handleCloseSnackbar = () => {
    setShowResponseMessage(false);
  };
  const createResponseMessage = (icon, message) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ marginLeft: '10px' }}>{message}</span>
      </div>
    );
  };

  const handleDeleteClick = (event, node) => {
    event.stopPropagation(); // Empêche le clic de se propager au TreeItem parent
  
    // Confirmez la suppression avec l'utilisateur
    const isConfirmed = window.confirm(`Are you sure you want to delete ${node.name}?`);
    
    if (isConfirmed) {
      // Appel API pour supprimer le fichier/dossier
      axios.post(`/api/file/delete`, { path: node.path })
        .then(() => {
          setResponseMessage(createResponseMessage(<FaCheckCircle color="green" />, "File "+node.name+" deleted."));
          setShowResponseMessage(true);
        })
        .catch(error => {
          setResponseMessage(createResponseMessage(<FaTimesCircle color="red" />, 'Impossible to delete file : ' + error.response.data.error));
          setShowResponseMessage(true);
          console.error('Error deleting file:', error);
          // Affichez un message d'erreur si la suppression échoue
        });
    }
  };

  return (
    <div>
    <Snackbar
      open={showResponseMessage}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={responseMessage}
      />
      { fileExist ? 
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<FaFolderPlus color='#FAD55E'/>}
      defaultEndIcon={<CiFileOn />}
      
    >
      {files.map((file) => renderTree(file))}
    </TreeView>
    : <h2>No files in file manager</h2>}
  </div>
    
  );
};

export default ListFiles;
