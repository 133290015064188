import React, { createContext, useContext, useEffect, useMemo, useState, ReactNode, useCallback } from "react";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

interface AuthContextType {
  isAuthenticated: boolean;
  role: string;
  login: (newToken: string) => void;
  logout: () => void;
}

interface CustomJwtPayload {
  role: string; // ou `string` si `role` est une chaîne unique
}


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"));

  const verifyToken = async (token: string) => {
    try {
      const response = await axios.get('/api/auth/check/token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Since your API does not return a field named `isValid`, we check if the response has `ok`
      if (response.data.ok) {
        setIsAuthenticated(true);
        const data = jwtDecode<CustomJwtPayload>(token);
        setRole(data.role);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      } else {
        logout();
        throw new Error('Token validation failed');

      }
    } catch (error) {
      console.error('Token verification failed:', error);
      setIsAuthenticated(false);
      setRole('');
      logout(); // Logout if the token is invalid or verification fails
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
    } else {
      setIsAuthenticated(false);
      setRole("");
    }
  }, [token, verifyToken]);


  const login = (newToken: string) => {
    localStorage.setItem("token", newToken);
    setToken(newToken);
  }; // Ajoutez ici les dépendances qui, lorsqu'elles changent, nécessiteraient que login soit recréé
  
  const logout = () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    setIsAuthenticated(false);
    setRole("");
  };

  const contextValue = useMemo(() => ({
    isAuthenticated,
    role,
    login,
    logout,
  }), [isAuthenticated, role, login, logout]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
