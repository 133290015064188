import React, { useState, useEffect, useRef } from 'react';
import EggManagerConsoleModal from './modals/EggManagerConsole_modal';
import { FaCheckCircle, FaTimesCircle, FaTrash, FaTerminal } from 'react-icons/fa';
import { PiEggCrackFill } from "react-icons/pi";

import axios from 'axios';

type Egg = {
  _id: string;
  last_activities: Date;
  egg_id: string;
  current_user: string;
  asset: string;
  listener: string;
  path: string;
  sleep: number;
  name?: string;
  listenerName?: string; // Supposé être une chaîne maintenant
  assetData?: Asset; // Supposé être une chaîne maintenant
  running?: boolean; // Nouveau champ pour le statut running
  admin?: boolean; // check si l'egg est admin
};

type Asset = {
  _id: string;
  mac: string;
  hostname: string;
  arch: string;
  os: string;
  ip?: string;
  pwn: boolean;
  network?: string;
  user?: string; // Supposé être une chaîne maintenant
};

const EggsTable: React.FC = () => {
  const [eggs, setEggs] = useState<Egg[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEgg, setSelectedEgg] = useState<Egg>();
  const selectedEggRef = useRef<Egg>();
  const selectedisModalOpen = useRef(false);
  const eggsRef = useRef(eggs);

  useEffect(() => {
  eggsRef.current = eggs;
}, [eggs]);

useEffect(() => {
  selectedEggRef.current = selectedEgg;
}, [selectedEgg]);

useEffect(() => {
  selectedisModalOpen.current = isModalOpen;
}, [isModalOpen]);

  const handleOpenModal = (egg: Egg) => {
    setSelectedEgg(egg);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchEggsAndListeners = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get<Egg[]>('/api/egg/list');
        const eggsWithListenerNames = await Promise.all(response.data.map(async (egg) => {
          let listenerName = ''; // Initialisez comme chaîne vide
          let assetData: Asset; // Initialisez comme chaîne vide

          if (egg.listener) {
            // Ici, vous pouvez ajuster selon votre logique de récupération de noms
            listenerName = await fetchListenerNameById(egg.listener); // Assurez-vous que cette fonction renvoie une chaîne
          }

          if(egg.asset) {
            assetData = await fetchAssetNameById(egg.asset);
          }

          // Calcul de running
          const isRunning = new Date().getTime() - new Date(egg.last_activities).getTime() < egg.sleep * 1000;

          return { ...egg, listenerName: listenerName, running: isRunning, assetData: assetData };
        }));
        setEggs(eggsWithListenerNames);
        setError(null);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.response?.data.error || error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchEggsAndListeners();
  }, []);

  useEffect(() => {
    const updateRunningStatus = () => {
      setEggs((currentEggs) => currentEggs.map((egg) => {
        const isRunning = new Date().getTime() - new Date(egg.last_activities).getTime() < egg.sleep * 1000;
        return { ...egg, running: isRunning };
      }));
    };
  
    const intervalId = setInterval(updateRunningStatus, 1000);
  
    return () => clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage
  }, []);
  

  useEffect(() => {
    // Si le protocole est HTTP, utilisez ws:// sinon wss://
    let ws: WebSocket;
    let protocol: string;
    
    if (window.location.protocol === 'http:') {
       protocol = 'ws';
    } else {
       protocol = 'wss';
    }
    ws = new WebSocket(protocol+'://'+window.location.hostname+ ':' + window.location.port+'/api/');
    
  
    ws.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      switch (message.action) {
        case 'deleteEgg':
          setEggs(eggsRef.current.filter(egg => egg.egg_id !== message.eggId));
          if (message.eggId === selectedEggRef.current?.egg_id && selectedisModalOpen.current) {
            setIsModalOpen(false);
          }
          
          console.log(message.eggId + ' deleted !')
          
          break;
        case 'log':
            // Retirer l'Egg supprimé de l'état local
            console.log(message.message);
            break;
        case 'addOrUpdateEgg':
          if (!message.egg) {return}
          let listenerName = ''; // Initialisez comme chaîne vide
          let assetData: Asset; // Initialisez comme chaîne vide

          if (message.egg.listener) {
            // Ici, vous pouvez ajuster selon votre logique de récupération de noms
            listenerName = await fetchListenerNameById(message.egg.listener); // Assurez-vous que cette fonction renvoie une chaîne
          }

          if(message.egg.asset) {
            assetData = await fetchAssetNameById(message.egg.asset);
          }

          // Calcul de running
          const isRunning = new Date().getTime() - new Date(message.egg.last_activities).getTime() < message.egg.sleep * 1000;
          const eggAllData = { ...message.egg, listenerName: listenerName, running: isRunning, assetData: assetData }
          const updatedEggs = eggsRef.current.filter(egg => egg.egg_id !== eggAllData.egg_id);
          updatedEggs.push(eggAllData);
          setEggs(updatedEggs);
          break;
        default:
          console.log('Action inconnue');
      }
    };
    return () => {
      ws.close();
    };
  }, []);

  // Assurez-vous d'ajuster cette fonction pour qu'elle renvoie une chaîne plutôt qu'un tableau
  const fetchListenerNameById = async (listenerId: string) => {
    try {
      const response = await axios.get(`/api/listener/list?_id=${listenerId}`);
      return response.data[0].name; // Renvoie directement le nom
    } catch (error) {
      console.error('Failed to fetch listener name:', error);
      return ''; // Renvoie une chaîne vide en cas d'erreur
    }
  };

  const fetchAssetNameById = async (assetId: string) => {
    try {
      const response = await axios.get<Asset>(`/api/asset/list?_id=${assetId}`);
      return response.data[0]; // Renvoie directement le nom
    } catch (error) {
      console.error('Failed to fetch listener name:', error);
      return { // Retourne un objet Asset vide ou avec des valeurs par défaut
        _id: 'No asset linked to egg. Please run the checkin command',
        mac: 'No asset linked to egg. Please run the checkin command',
        hostname: 'No asset linked to egg. Please run the checkin command',
        arch: 'No asset linked to egg. Please run the checkin command',
        os: 'No asset linked to egg. Please run the checkin command',
        pwn: false,
      }; // Renvoie une chaîne vid/ Renvoie une chaîne vide en cas d'erreur
    }
  };


  const fetchDeleteEggById = async (eggId: string) => {
    try {
      const response = await axios.get(`/api/egg/delete/${eggId}`);
    } catch (error) {
      console.error('Failed to delete egg by id:', error);
      return ''; // Renvoie une chaîne vide en cas d'erreur
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (eggs.length === 0) return <p>No egg found.</p>;

  return (
    <div>
      <h2>List of Eggs</h2>
      <table>
      <thead>
  <tr>
    <th></th>
    <th>Running</th>
    <th>ID</th>
    <th>Asset</th>
    <th>Current username</th>
    <th>Path</th>
    <th>Listener</th>
    <th>Name</th>
    <th>Sleep</th>
    <th>Actions</th> {/* Ajout d'une en-tête de colonne pour les actions */}
  </tr>
</thead>
<tbody>
  {eggs.map((egg) => (
    <tr key={egg.egg_id} >
      <td style={{textAlign: 'center'}}>{egg.admin ? <PiEggCrackFill color="red"/> : ''}</td>
      <td style={{textAlign: 'center'}}>{egg.running ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />}</td>
      <td>{egg.egg_id}</td>
      <td>{egg.asset ? egg.assetData?.hostname : 'No asset linked to egg. Please run the checkin command'}</td>
      <td>{egg.current_user}</td>
      <td>{egg.path}</td>
      <td>{egg.listenerName ? egg.listenerName : 'The listener no longer exists. This egg will continue to rotate without you being able to interact with it :('}</td>
      <td>{egg.name ? egg.name : 'Impossible to get name. Please checkin'}</td>
      <td>{egg.sleep}</td>
      <td>
      <FaTerminal onClick={() => handleOpenModal(egg)}/>
      <FaTrash onClick={() => fetchDeleteEggById(egg.egg_id)}/>
      </td>
    </tr>
  ))}
</tbody>

      </table>
      <EggManagerConsoleModal className='console' isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} egg={selectedEgg} />
    </div>
    
  );
};

export default EggsTable;
