import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './generatePayload.css';
import { Modal, Box, Button, Select, MenuItem, FormControl, InputLabel, List, ListItem, TextareaAutosize } from '@mui/material';

// Assurez-vous que votre type Agent est correctement défini
type Agent = {
  name: string;
  folder: string;
  targets: string[]; // Assurez-vous que cette propriété correspond à votre modèle de données
  crosscompile: boolean; // Assurez-vous que cette propriété correspond à votre modèle de données
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  listener: Listener;
};

const GeneratePayloadModal: React.FC<Props> = ({ isOpen, onClose, listener }) => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<string>('');
  const [selectedAgentCrossCompile, setCrossCompile] = useState<boolean>(false);
  const [targets, setTargets] = useState<string[]>([]);
  const [terminalOutput, setTerminalOutput] = useState<string[]>([]);
  const terminalRef = useRef(null);
  const [showTerminal, setShowTerminal] = useState(false); // Nouvel état pour contrôler l'affichage du terminal
  const [jsonModalOpen, setJsonModalOpen] = useState(false);
  const [profilesJson, setProfilesJson] = useState('');
  const [nginxConfig, setNginxConfig] = useState('');
  const [nginxModalOpen, setNginxModalOpen] = useState(false);

  // Fonctions existantes

  const fetchProfilesJson = async () => {
    try {
      const response = await axios.get(`/api/egg/json/generate/${listener._id}`);
      setProfilesJson(JSON.stringify(response.data, null, 2)); // Formate le JSON pour l'affichage
      setJsonModalOpen(true); // Ouvre la modal contenant le JSON
    } catch (error) {
      console.error('Failed to fetch profiles JSON:', error);
      alert('Failed to fetch profiles JSON.');
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Text copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
      alert('Failed to copy text.');
    });
  };

  const fetchNginxConfig = async () => {
    try {
      const response = await axios.get(`/api/listener/generate-reverse/${listener._id}`);
      setNginxConfig(response.data); // Stocke la configuration Nginx
      setNginxModalOpen(true); // Ouvre la modal contenant la configuration Nginx
    } catch (error) {
      console.error('Failed to fetch nginx config:', error);
      alert('Failed to fetch nginx config.');
    }
  }

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get('/api/egg/list/henhouse');
        setAgents(response.data); // Adaptez cette ligne si le format de réponse n'est pas un tableau direct
      } catch (error) {
        console.error('Failed to fetch agents:', error);
      }
    };

    if (isOpen) {
      fetchAgents();
    }
  }, [isOpen]);

  useEffect(() => {
    setTerminalOutput([]);
    setShowTerminal(false);
  
  }, [listener]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
     // Si le protocole est HTTP, utilisez ws:// sinon wss://
     let ws: WebSocket;
     let protocol: string;
     
     if (window.location.protocol === 'http:') {
        protocol = 'ws';
     } else {
        protocol = 'wss';
     }
     ws = new WebSocket(protocol+'://'+window.location.hostname+ ':' + window.location.port+'/api/');
     
  
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      switch (message.action) {
        case 'log':
          console.log(message.message);
          break;
        case 'buildPayloadLog':
          if (!message.message) return;
          setTerminalOutput((prevOutput) => [...prevOutput, message.message]);

          break;
        default:
          console.log('Action inconnue');
      }
    };
    return () => {
      ws.close();
    };
  }, [isOpen]); // Cet effet ne dépend d'aucune variable extérieure

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [terminalOutput]); // Se déclenche à chaque mise à jour de terminalOutput
  

  useEffect(() => {
    // Mettre à jour les targets chaque fois que l'agent sélectionné change
    const agent = agents.find(a => a.name === selectedAgent);
    setTargets(agent?.targets || []);
    setCrossCompile(agent?.crosscompile || false);
  }, [selectedAgent, agents]);

  const handleGenerate = async () => {
    if (!selectedAgent) {
      alert('Please select an agent.'); // Remplacer par votre gestion d'erreur préférée
      return;
    }
  
    // Trouver l'agent sélectionné dans la liste des agents
    const agent = agents.find(a => a.name === selectedAgent);
    if (!agent) {
      alert('Selected agent not found.'); // Remplacer par votre gestion d'erreur préférée
      return;
    }
    setShowTerminal(true); // Afficher le terminal avant de lancer la génération

    try {
      // Effectuer une requête POST pour générer l'agent
      const response = await axios.get(`/api/egg/generate/${listener._id}/${agent.folder}`, {  responseType: 'blob', timeout: 1000000 });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
  
      // Spécifiez le nom de fichier pour le téléchargement (optionnel)
      link.setAttribute('download', selectedAgent); // 'rust' ou un autre nom basé sur la réponse
      document.body.appendChild(link);
      link.click();
  
      // Nettoyez en supprimant le lien temporaire
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      console.log(response);
    } catch (error) {
      console.error('Failed to generate agent:', error);
      alert('Failed to generate agent.'); // Notification d'erreur, à remplacer par votre gestion d'erreur préférée
    }
  };
  

  return (
    <Modal open={isOpen} onClose={onClose} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Box className="boxModalPayload">
        <h2>Generate Payload for {listener?.name}</h2>
        <FormControl fullWidth>
          <InputLabel id="agent-select-label">Agent</InputLabel>
          <Select
            labelId="agent-select-label"
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            label="Agent"
          >
            {agents.map((agent) => (
              <MenuItem key={agent.name} value={agent.name}>{agent.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {targets.length > 0 && (
          <div>
            <h3>Targets</h3>
            <List>
              {targets.map((target, index) => (
                <ListItem key={index}>{target}</ListItem>
              ))}
            </List>
          </div>
        )}
        <Button variant="contained" color="primary" onClick={fetchProfilesJson} style={{ marginTop: '20px' }}>
          Show Profiles JSON
        </Button>
        <Button variant="contained" color="primary" onClick={fetchNginxConfig} style={{ marginTop: '20px' }}>
          Show Nginx Config
        </Button>
        {selectedAgent ? 
        <div className='divButton'>
         
          { selectedAgentCrossCompile ? 
          <Button variant="contained" onClick={handleGenerate} style={{ marginTop: '20px' }}>
          Generate
        </Button> 
        :
        <p>Cross compilation not configured for this agent. Please build code localy</p>
           }
        
        </div> :<p>Select an egg</p> }

        {showTerminal && <div className='terminalBuilder' ref={terminalRef}>
        <pre>{terminalOutput.join('\n')}</pre>
        </div>} 

        <Modal className='modalJson' open={jsonModalOpen} onClose={() => setJsonModalOpen(false)}>
          <Box className='insideModalJson' >
            <TextareaAutosize
              aria-label="Profiles JSON"
              minRows={10}
              style={{ width: 350 }}
              value={profilesJson}
              readOnly
            />
            <Button onClick={() => copyToClipboard(profilesJson)} style={{ marginTop: '10px' }}>
              Copy JSON
            </Button>
          </Box>
        </Modal>

        <Modal className='modalJson' open={nginxModalOpen} onClose={() => setNginxModalOpen(false)}>
          <Box className='insideModalJson' >
            <TextareaAutosize
              aria-label="Nginx Config"
              minRows={10}
              style={{ width: 350 }}
              value={nginxConfig}
              readOnly
            />
            <Button onClick={() => copyToClipboard(nginxConfig)} style={{ marginTop: '10px' }}>
              Copy Nginx Config
            </Button>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default GeneratePayloadModal;
