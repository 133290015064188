import React from 'react';
import Header from '../../components/core/Header'; // Vérifiez le chemin d'importation
import SideMenu from '../../components/core/SideMenu'; // Vérifiez le chemin d'importation
import ListListener from '../../components/listener/ListListener'; // Vérifiez le chemin d'importation
import CreateListener from '../../components/listener/CreateListener'; // Vérifiez le chemin d'importation


import '../../App.css';


const ListenerPage = () => {
    
    return (
        <div className='body'>
            <SideMenu/>
            <div className='core'>
                <Header title='Listener'/>
                <div className='content'>
                    <div className='component'> <CreateListener/></div>
                    <div className='component'><ListListener/></div>
                </div>
            </div>
        </div>
    );

};

export default ListenerPage;
