import React from 'react';
import Dashboard from '../../components/Dashboard'; // Vérifiez le chemin d'importation
import Header from '../../components/core/Header'; // Vérifiez le chemin d'importation
import SideMenu from '../../components/core/SideMenu'; // Vérifiez le chemin d'importation
import '../../App.css';



const DashboardPage = () => {
    // Les données à passer au composant Dashboard
    const dashboardTitle = "Titre de Mon cil";

    return (
            <div className='body'>
                <SideMenu/>
                <div className='core'>
                <Header title='Dashboard'/>
                    <div className='content'>
                        <Dashboard title={dashboardTitle} />
                    </div>
                </div>
            </div>
    );
};

export default DashboardPage;
