import React from 'react';
import Header from '../../components/core/Header'; // Vérifiez le chemin d'importation
import SideMenu from '../../components/core/SideMenu'; // Vérifiez le chemin d'importation
import ListListener from '../../components/listener/ListListener'; // Vérifiez le chemin d'importation
import CreateListenerProfile from '../../components/listener/CreateListenerProfile'; // Vérifiez le chemin d'importation
import ListListenerProfile from '../../components/listener/ListListenerProfile'; // Vérifiez le chemin d'importation


import '../../App.css';


const ListenerProfilePage = () => {
    
    return (
        <div className='body'>
            <SideMenu/>
            <div className='core'>
                <Header title='Listener profile'/>
                <div className='content'>
                    <div className='component'> <CreateListenerProfile/></div>
                    <div className='component'> <ListListenerProfile/></div>

                </div>
            </div>
        </div>
    );

};

export default ListenerProfilePage;
