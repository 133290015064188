import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

type FakeDataItem = {
  http: string;
  parameter: string;
  field: string;
  value: string;
};

type FormState = {
  name: string;
  type: string;
  domain: string;
  port: string;
  endpoint: string;
  data_parameter: string;
  data_method: string;
  data_field: string;
  data_encode: string;
  response_field: string;
  response_parameter: string;
  encrypt_method?: string;
  key?: string;
  encrypted: boolean;
  fake_data: FakeDataItem[];
};

const CreateListenerProfileForm: React.FC = () => {
  const [formData, setFormData] = useState<FormState>({
    name: '',
    type: '',
    domain: '',
    port: '',
    endpoint: '',
    data_parameter: '',
    data_method: '',
    data_field: '',
    data_encode: '',
    response_field: '',
    response_parameter: '',
    encrypted: false,
    fake_data: [],
    encrypt_method: '',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [encodingTypes, setEncodingTypes] = useState<string[]>([]);
  const [encryptionMethods, setEncryptionMethods] = useState<string[]>([]);
  const [profileTypes, setProfileTypes] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEncodingTypes = async () => {
      try {
        const response = await axios.get('/api/listener/get/encoding');
        console.log('Encoding types:', response.data.decodingTypes); // Debug log
        setEncodingTypes(response.data.decodingTypes);
      } catch (error) {
        console.error('Failed to fetch encoding types:', error);
      }
    };

    const fetchEncryptionMethods = async () => {
      try {
        const response = await axios.get('/api/listener/get/encryption');
        console.log('Encryption methods:', response.data.encryptionMethods); // Debug log
        setEncryptionMethods(response.data.encryptionMethods);
      } catch (error) {
        console.error('Failed to fetch encryption methods:', error);
      }
    };

    fetchEncodingTypes();
    fetchEncryptionMethods();
  }, []);

  useEffect(() => {
    const fetchProfileTypes = async () => {
      try {
        const response = await axios.get('/api/listener/list/types');
        console.log('Profile types:', response.data.typesSupported); // Debug log
        const types = response.data.typesSupported;
        setProfileTypes(types);
        if (types.length > 0 && !formData.type) {
          setFormData(prevFormData => ({ ...prevFormData, type: types[0] }));
        }
      } catch (error) {
        console.error('Failed to fetch profile types:', error);
      }
    };

    fetchProfileTypes();
  }, [formData.type]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, checked } = event.target;
    console.log(`Changing ${name} to ${event.target.type === 'checkbox' ? checked : value}`); // Debug log
    setFormData(prevData => ({
      ...prevData,
      [name]: event.target.type === 'checkbox' ? checked : value,
    }));
  };

  const handleFakeDataChange = (index: number, field: string, value: string) => {
    setFormData(prevData => {
      const newFakeData = [...prevData.fake_data];
      newFakeData[index] = { ...newFakeData[index], [field]: value };
      return { ...prevData, fake_data: newFakeData };
    });
  };

  const handleAddFakeData = () => {
    setFormData(prevData => ({
      ...prevData,
      fake_data: [...prevData.fake_data, { http: '', parameter: '', field: '', value: '' }]
    }));
  };

  const handleRemoveFakeData = (index: number) => {
    setFormData(prevData => {
      const newFakeData = prevData.fake_data.filter((_, i) => i !== index);
      return { ...prevData, fake_data: newFakeData };
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log('Form data before submission:', formData); // Debug log

    try {
      await axios.post('/api/listener/create/profile', formData);
      alert('Profile successfully created.');
      navigate('/red/listener/profile');
    } catch (error) {
      alert(`Failed to create profile. ${error}`);
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2>Create Listener Profile</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Profile Name:</label>
          <input id="name" name="name" type="text" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="type">Type:</label>
          <select id="type" name="type" value={formData.type} onChange={handleChange} required>
            {profileTypes.map(type => (
              <option key={type} value={type}>{type.toUpperCase()}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="domain">Domain:</label>
          <input id="domain" name="domain" type="text" value={formData.domain} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="port">Port:</label>
          <input id="port" name="port" type="text" value={formData.port} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="endpoint">Endpoint:</label>
          <input id="endpoint" name="endpoint" type="text" value={formData.endpoint} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="data_method">Data Method:</label>
          <select id="data_method" name="data_method" value={formData.data_method} onChange={handleChange} required>
            <option value="">Select Method</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="PATCH">PATCH</option>
          </select>
        </div>
        <div>
          <label htmlFor="data_parameter">Data Parameter:</label>
          <select id="data_parameter" name="data_parameter" value={formData.data_parameter} onChange={handleChange} required>
            <option value="">Select Field</option>
            <option value="parameter">Parameter</option>
            <option value="header">Header</option>
            <option value="cookie">Cookie</option>
          </select>
        </div>
        <div>
          <label htmlFor="data_field">Data Field:</label>
          <input id="data_field" name="data_field" type="text" value={formData.data_field} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="data_encode">Data Encode:</label>
          <select id="data_encode" name="data_encode" value={formData.data_encode} onChange={handleChange} required>
            <option value="">Select Encoding</option>
            {encodingTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="response_parameter">Response Parameter:</label>
          <select id="response_parameter" name="response_parameter" type="text" value={formData.response_parameter} onChange={handleChange} required >
            <option value="">Select Field</option>
            <option value="body">Body</option>
            <option value="header">Header</option>
            <option value="cookie">Cookie</option>
          </select>
        </div>
        <div>
          <label htmlFor="response_field">Response Field:</label>
          <input id="response_field" name="response_field" type="text" value={formData.response_field} onChange={handleChange} required />
        </div>
        <div>
          <label>
            <input type="checkbox" name="encrypted" checked={formData.encrypted} onChange={handleChange} />
            Encrypted?
          </label>
        </div>
        {formData.encrypted && (
          <>
            <div>
              <label htmlFor="encrypt_method">Data Encrypt Method:</label>
              <select id="encrypt_method" name="encrypt_method" value={formData.encrypt_method} onChange={handleChange}>
              <option value="">Select method to encrypt</option>
                {encryptionMethods.map(method => (
                  <option key={method} value={method}>{method}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="key">Encryption Key:</label>
              <input id="key" name="key" type="text" value={formData.key || ''} onChange={handleChange} />
            </div>
          </>
        )}
        <div>
          <h3>Fake Data</h3>
          {formData.fake_data.map((item, index) => (
            <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
              <select
                name="http"
                value={item.http}
                onChange={e => handleFakeDataChange(index, 'http', e.target.value)}
                required
              >
                <option value="">Select HTTP type</option>
                <option value="request">Request</option>
                <option value="response">Response</option>
              </select>
              <select
                name="parameter"
                value={item.parameter}
                onChange={e => handleFakeDataChange(index, 'parameter', e.target.value)}
                required
              >
                <option value="">Select parameter</option>
                <option value="header">Header</option>
                <option value="cookie">Cookie</option>
                <option value="get">Get</option>
                <option value="body">Body</option>
              </select>
              <input
                name="field"
                type="text"
                value={item.field}
                onChange={e => handleFakeDataChange(index, 'field', e.target.value)}
                placeholder="Field"
                required
              />
              <input
                name="value"
                type="text"
                value={item.value}
                onChange={e => handleFakeDataChange(index, 'value', e.target.value)}
                placeholder="Value"
                required
              />
              <button type="button" onClick={() => handleRemoveFakeData(index)}>Remove</button>
            </div>
          ))}
          <button type="button" onClick={handleAddFakeData}>Add Fake Data</button>
        </div>
        <button type="submit" disabled={isSubmitting}>Create Profile</button>
      </form>
    </div>
  );
};

export default CreateListenerProfileForm;
