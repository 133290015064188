import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './css/SideMenu.css';
import { useAuth } from '../../pages/auth/AuthContext';
import { MdOutlineDashboard, MdOutlineAnalytics,MdOutlineEgg} from "react-icons/md";
import { GoDiamond } from "react-icons/go";
import { PiShareNetworkThin } from "react-icons/pi";
import { FaBookSkull } from "react-icons/fa6";
import { TbFlagBolt, TbFlagCog } from "react-icons/tb";
import { FaHeadphonesAlt } from "react-icons/fa";
import { CiSettings,CiFileOn } from "react-icons/ci";



function Sidebar() {
  const { role } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false); // État pour contrôler l'expansion
  const toggleSidebar = () => setIsExpanded(!isExpanded); // Fonction pour basculer l'état


  const links = [
    { to: "/white/dashboard", icon: <MdOutlineDashboard />,label: "Dashboard", roles: ["admin", "whitecell"] },
    { to: "/white/data_viewer", icon: <MdOutlineAnalytics />, label: "Data Viewer", roles: ["admin", "whitecell"] },
    { to: "/white/flag", icon: <TbFlagCog />, label: "Flag", roles: ["admin", "whitecell"] },
    { to: "/white/element", icon: <GoDiamond />, label: "Element", roles: ["admin", "whitecell"] },
    { to: "/red/egg_manager", icon: <MdOutlineEgg />, label: "Egg Manager", roles: ["admin", "redcell"] },
    { to: "/red/cartography",icon: <PiShareNetworkThin />,label: "Cartography", roles: ["admin", "redcell"] },
    { to: "/red/attackers", icon: <FaBookSkull />,label: "Attackers", roles: ["admin", "redcell"] },
    { to: "/red/flag", icon: <TbFlagBolt />,label: "Flag", roles: ["admin", "redcell"] },
    { to: "/red/file_manager", icon: <CiFileOn />,label: "File manager", roles: ["admin", "redcell"] },
    { to: "/red/listener", icon: <FaHeadphonesAlt />,label: "Listener", roles: ["admin", "user", "redcell"] },
    { to: "/settings", icon: <CiSettings />,label: "Settings", roles: ["admin", "redcell", "whitecell"] },
  ];

  return (
    <aside className={isExpanded ? 'expanded' : ''} onMouseEnter={toggleSidebar} onMouseLeave={toggleSidebar}>
      <nav>
        {links.filter(link => link.roles.includes("admin")).map(filteredLink => (
          <div className='blockOneIcon'>
          <NavLink key={filteredLink.to} to={filteredLink.to} className={({ isActive }) => isActive ? 'active' : undefined}>
            <div className='iconMenu'>{filteredLink.icon}</div>
            {isExpanded && <span>{filteredLink.label}</span>} {/* Affiche le label si isExpanded est vrai */}

          </NavLink>
        </div>
        ))}
      </nav>
      {/* <button onClick={toggleSidebar} className="toggle-button">Toggle</button> */}

    </aside>
  );
}

export default Sidebar;
